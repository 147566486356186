import listTypes from '../types/lists'

const initialState = {
  modal: '',
  lists: [],
  selectedList: {},
  selectedApplicantList: {},
  listsDetails: [],
  applicants: [],
  applicant: {},
  moveList: {},
  recruiter: {},
  pagination: {
    pageCount: 1,
    pageNumber: 1,
    pageSize: 10,
    rowCount: 1,
  },
}

const listsReducer = (state = initialState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case listTypes.SET_MODAL:
      return {
        ...state,
        modal: action.payload,
      }
    case listTypes.ADD_LISTS:
      return {
        ...state,
        lists: action.payload,
      }

    case listTypes.UPDATE_LISTS:
      return {
        ...state,
        lists: [...newState.lists, action.payload],
      }

    case listTypes.ADD_LIST:
      return {
        ...state,
        selectedList: action.payload,
      }

    case listTypes.SELECT_APPLICANT:
      return {
        ...state,
        applicant: action.payload,
      }

    case listTypes.SELECT_RECRUITER:
      return {
        ...state,
        recruiter: action.payload,
      }

    case listTypes.SHOW_APPLICANTS:
      return {
        ...state,
        applicants: action.payload,
      }

    case listTypes.MOVE_LIST:
      return {
        ...state,
        moveList: action.payload,
      }
    case listTypes.SELECTED_APPLICANT_LIST:
      return {
        ...state,
        selectedApplicantList: action.payload,
      }
    case listTypes.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      }

    default:
      return state
  }
}

export default listsReducer
