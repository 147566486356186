import React, { useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch, useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'

import useFetch from '../../../hooks/useFetch'
import {
  addLists,
  moveList,
  setModal,
} from '../../../store/actions/listsActions'
import Flex from '../../layouts/Flex'
import TalentCardPopList from './TalentCardPopList'
import TalentCardPopout from './TalentCardPopout'

const ShowLists = ({ talent, setShowLists }) => {
  const dispatch = useDispatch()
  // const { data, loading } = useFetch('/list-names')
  const { data, loading } = useFetch('/jobs/dropdown')

  const { lists } = useSelector((state) => state.lists)

  useEffect(() => {
    if (data) {
      dispatch(addLists(data.data))
    }
  }, [data])

  function handleMoveApplicant(list) {
    if (talent.listName) {
      dispatch(setModal('moveToList'))
    } else {
      dispatch(setModal('addToList'))
    }
    dispatch(moveList(list))
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowLists(false)
      }}
    >
      <TalentCardPopout right="-85%">
        {loading ? (
          <Flex height="3rem" justify="center" items="center">
            <ClipLoader color="#1a1a1a" size="1rem" />
          </Flex>
        ) : (
          <>
            {lists.map((list) => ( list.status === 'active' ?
              <TalentCardPopList
                onClick={() => handleMoveApplicant(list)}
                text={`${list.title.substring(0, 20)}${
                  list.title.length > 20 ? '...' : ''
                }`}
                key={list.id}
              /> : null
            ))}
          </>
        )}
      </TalentCardPopout>
    </OutsideClickHandler>
  )
}

export default ShowLists