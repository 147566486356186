import React from 'react'

import styled from 'styled-components'

import right from '../../../assets/icons/chevron-right.png'

const TalentCardPopList = ({ text = '', active, onClick, rightArrow }) => {
  return (
    <StyldTalentPopList onClick={onClick} className={active ? 'active' : ''}>
      {text}
      {active || (rightArrow && <img src={right} alt="right" />)}
    </StyldTalentPopList>
  )
}

export default TalentCardPopList

const StyldTalentPopList = styled.div`
  width: 100%;
  height: 2rem;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background 0.2s ease-in-out;
  font-size: 0.875rem;
  font-weight: 300;
  color: #0e1324;
  cursor: pointer;

  img {
    width: 0.75rem;
    height: auto;
  }

  &:hover,
  &.active {
    background: #eeeef6;
  }
`
