export const markText = (inputString, wordToMark) => {
  if (wordToMark && inputString) {
    const escapedWord = wordToMark.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const regex = new RegExp(escapedWord, 'gi')
    const markedString = inputString.replace(
      regex,
      (match) => `<mark>${match}</mark>`
    )
    return markedString
  }
  return inputString
}

export const countTruthyPropertiesOfObject = (state, arr) => {
  return arr.reduce((count, property) => {
    if (
      Object.prototype.hasOwnProperty.call(state, property) &&
      state[property] != null &&
      state[property] !== '' &&
      state[property] !== 0 &&
      state[property] !== false &&
      state[property]?.length > 0
    ) {
      return count + 1
    } else {
      return count
    }
  }, 0)
}

export const isValidJson = (str) => {
  if (str) {
    try {
      JSON.parse(str)
      return true
    } catch (e) {
      return false
    }
  }
}

export const getCVImage = (applicant) => {
  return applicant?.image_url || applicant?.cv_image_url
}

export const generateRandomLightColor = () => {
  return 'hsl(' + Math.random() * 360 + ', 100%, 75%)'
}

export const parseLocalStorageJSON = (key) => {
  if (!key || typeof key !== 'string') {
    throw new Error('Invalid key')
  }

  /**
   * Handle non-string value with JSON.parse.
   * Catch string value and return it
   */
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch {
    return localStorage.getItem(key)
  }
}

// Reference: https://www.30secondsofcode.org/js/s/deep-equality-comparison/
export const equals = (a, b) => {
  if (a === b) return true

  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()

  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
    return a === b

  if (a.prototype !== b.prototype) return false

  const keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) return false

  return keys.every((k) => equals(a[k], b[k]))
}
