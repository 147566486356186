/* eslint-disable camelcase */
import axiosInstance from '../config/AxiosIntance'

function createJobPost({
  title,
  description,
  company_id,
  poc_id,
  job_type,
  education,
  language,
  experience,
  certification,
  software,
  hardware_requirement,
  shift_availability,
  min_salary,
  max_salary,
  salary_basis,
  requires_coaching,
  active_vacancy,
  vacancy_for_pooling,
  time_shift,
  technical_skills,
  personal_skills,
  benefits,
  client,
  availability_to_start,
  recruiter_id,
  priority,
}) {
  return axiosInstance.post('/job', {
    company_id,
    poc_id,
    title,
    description,
    job_type,
    education,
    language,
    time_shift,
    active_vacancy,
    vacancy_for_pooling,
    requires_coaching,
    certification,
    technical_skills,
    personal_skills,
    software,
    hardware_requirement,
    shift_availability,
    min_salary,
    max_salary,
    salary_basis,
    client,
    benefits,
    experience,
    recruiter_id,
    availability_to_start,
    priority,
  })
}

export default createJobPost
