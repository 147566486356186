import React from 'react'

import styled from 'styled-components'

const DashboardInput = ({
  name,
  label,
  type,
  placeholder,
  handleChange,
  handleKeyDown,
  value,
  height,
  inputInnerTag,
  maxWidth,
  width,
  margin,
  marginBottom,
  topRightContent,
  readOnly,
  errorMessage,
}) => {
  return (
    <StyledDashboardInput
      maxWidth={maxWidth}
      width={width}
      height={height}
      inputInnerTag={inputInnerTag}
      margin={margin}
      marginBottom={marginBottom}
      errorMessage={errorMessage}
    >
      {label && (
        <label
          htmlFor={name}
          className={`label ${errorMessage ? ' error' : ''}`}
        >
          {label}
        </label>
      )}
      <div className="input-wrapper">
        {inputInnerTag && <p className="innerTag">{inputInnerTag}</p>}
        {topRightContent && (
          <div className="topRightContent">{topRightContent}</div>
        )}

        <input
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          className="input"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={value}
          readOnly={readOnly}
        />
        {errorMessage && <div className="error__message">{errorMessage}</div>}
      </div>
    </StyledDashboardInput>
  )
}

export default DashboardInput

const StyledDashboardInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  margin: ${({ margin }) => margin || '0'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '1rem'};

  .label {
    font-size: 0.75rem;
    font-weight: 300;
    color: #0e1324;
    margin-bottom: 4px;
    text-align: left;

    &.error {
      color: red;
    }
  }

  .input-wrapper {
    width: 100%;
    height: ${({ height }) => height || '100%'};
    position: relative;
    margin-bottom: ${({ errorMessage }) => errorMessage && '0.5rem'};

    .innerTag {
      position: absolute;
      top: 50%;
      left: 0.75rem;
      transform: translateY(-50%);
      font-size: 0.75rem;
      font-weight: 700;
      color: #0e1324;
    }

    .topRightContent {
      position: absolute;
      top: -1.25rem;
      right: 0;
    }

    .error__message {
      font-size: 0.75rem;
      font-weight: 300;
      /* position: absolute;
      top: 2.5rem;
      left: 0; */
      color: red;
    }

    .input {
      width: 100%;
      height: ${({ height }) => height || '2rem'};
      background: #fff;
      outline: none;
      border: ${({ errorMessage }) =>
        errorMessage ? '1px solid red' : '1px solid #aeb3c9'};
      padding: 0.5rem;
      padding-left: ${({ inputInnerTag }) =>
        inputInnerTag ? '3.5rem' : '0.5rem'};
      font-size: 0.75rem;
      color: ${({ errorMessage }) => (errorMessage ? 'red' : '#0e1324')};
      font-weight: 300;
    }
  }
`
