/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ToastContainer, toast } from 'react-toastify'

import {
  SET_ACTIVE_VACANCY,
  SET_COMPANY_ID,
  SET_JOB_DESCRIPTION,
  SET_JOB_TITLE,
  SET_JOB_TYPE,
  SET_POC_ID,
  SET_POLLING,
  SET_REACH_STEP,
  SET_REQUIRES_COACHING,
  SET_PRIORITY,
  SET_TIME_SHIFT,
  SET_VACANCY_FOR_POOLING,
  SWITCH_STEP,
} from '../../../../store/types/jobPosting'

import Flex from '../../../../components/layouts/Flex'
import { H2 } from '../../../../style/Typography.style'

import DashboardInput from '../../../../components/dashboard/DashboardInput'
import { SingleDropdownSelect } from '../../../../components/dashboard/TalentPool/DropdownSelect'
import { JobToggleButton } from '../../../../components/dashboard/ToggleButton'
import { FilterCheckBox } from '../../../../components/dashboard/FilterInput'
import RichTextbox from '../../../../components/dashboard/controls/RichTextBox'
import DashboardButton from '../../../../components/dashboard/DashboardButton'
import useFetch from '../../../../hooks/useFetch'
import { setRecruiterId } from '../../../../store/actions/jobPostingActions'

import axiosInstance from '../../../../services/config/AxiosIntance'

const JobDetails = () => {
  const dispatch = useDispatch()

  const { data: recruiters } = useFetch('/recruiters')

  const {
    polling,
    requires_coaching,
    priority,
    description,
    title,
    job_type,
    active_vacancy,
    vacancy_for_pooling,
    time_shift,
    client,
    poc_id,
    company_id,
    recruiter_id,
  } = useSelector((state) => state.jobPosting)

  const [companies, setCompanies] = useState([])
  const [contacts, setContacts] = useState([])

  // handle pooling
  const handlePooling = () => {
    dispatch({ type: SET_POLLING })
    dispatch({
      type: SET_VACANCY_FOR_POOLING,
      payload: 0,
    })
  }

  const handleDescription = (value) => {
    dispatch({ type: SET_JOB_DESCRIPTION, payload: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (title === '')
      return toast('Please enter a job title', { type: 'error' })
    if (description === '')
      return toast('Please enter a job summary', { type: 'error' })
    if (job_type === '')
      return toast('Please select a Employment type', { type: 'error' })
    if (active_vacancy === '')
      return toast('Please enter a active vacancy', { type: 'error' })
    if (client === '') return toast('Please select a client', { type: 'error' })
    if (time_shift === '')
      return toast('Please select a time shift', { type: 'error' })
    if (recruiter_id && recruiter_id === '')
      return toast('Please select a recruiter', { type: 'error' })
    if (poc_id && poc_id === '')
      return toast('Please select a contact', { type: 'error' })

    dispatch({ type: SWITCH_STEP, payload: 2 })
    dispatch({ type: SET_REACH_STEP, payload: 2 })
    localStorage.setItem('reachedStep', 2)
    localStorage.setItem('step', 2)
    localStorage.setItem(
      'jobDetails',
      JSON.stringify({
        polling,
        requires_coaching,
        priority,
        description,
        title,
        job_type,
        active_vacancy,
        vacancy_for_pooling,
        time_shift,
        client,
        company_id,
        poc_id,
        recruiter_id,
      })
    )
  }

  useEffect(() => {
    axiosInstance.get('/company?client_status_id=1').then((response) => {
      setCompanies(response?.data?.data)
    })
  }, [])
  useEffect(() => {
    axiosInstance
      .get(`/poc?company_id=${company_id}&status_id=1`)
      .then((response) => {
        setContacts(response?.data?.data)
      })
  }, [company_id])

  const handleClientChange = (e) => {
    const client = companies.find(
      (client) => client.name.toString() === e.target.value
    )
    dispatch({
      type: SET_COMPANY_ID,
      payload: JSON.stringify(client),
    })
  }
  const handleContactChange = (e) => {
    const contact = contacts.find(
      (contact) => contact.id.toString() === e.target.value
    )
    dispatch({
      type: SET_POC_ID,
      payload: JSON.stringify(contact),
    })
  }

  return (
    <Flex
      margin="2.5rem 1rem 9rem 1rem"
      direction="column"
      width="100%"
      maxw="500px"
      maxwMobile="300px"
    >
      <H2>Job Details</H2>
      <DashboardInput
        label="Job Title"
        placeholder="e.g. Graphic Designer, Software Engineer"
        name="jobTitle"
        handleChange={(e) =>
          dispatch({ type: SET_JOB_TITLE, payload: e.target.value })
        }
        type="text"
        value={title}
        height="2.5rem"
      />
      {/* Engagement type field needs options and state created */}
      <SingleDropdownSelect
        onChange={(e) =>
          dispatch({ type: SET_JOB_TYPE, payload: e.target.value })
        }
        value={job_type}
        title="Engagement type"
      >
        <option>Choose an option</option>
        <option value="full-time">Full time</option>
        <option value="part-time">Part time</option>
      </SingleDropdownSelect>
      {/* creating double type input box */}
      <Flex width="100%" mobileDirection="column">
        <FilterCheckBox
          checked={polling}
          size="small"
          pos="absolute"
          top="-.05rem"
          right="-1.75rem"
          label="For Pooling"
          name="ProfessionalHeadline"
          onChange={handlePooling}
        />
        <DashboardInput
          label="Active Vacancies"
          placeholder={0}
          value={active_vacancy}
          handleChange={(e) => {
            if (e.target.value < 0) return
            dispatch({ type: SET_ACTIVE_VACANCY, payload: e.target.value })
          }}
          name="activeVacancies"
          type="number"
          height="2.5rem"
        />
        {polling && (
          <DashboardInput
            label="Vacancy for Pooling"
            placeholder={0}
            value={vacancy_for_pooling}
            handleChange={(e) => {
              if (e.target.value < 0) return
              dispatch({
                type: SET_VACANCY_FOR_POOLING,
                payload: e.target.value,
              })
            }}
            name="vacancyForPooling"
            type="number"
            height="2.5rem"
          />
        )}
      </Flex>
      {/* employment type field needs options and state created */}
      <SingleDropdownSelect
        onChange={(e) =>
          dispatch({ type: SET_TIME_SHIFT, payload: e.target.value })
        }
        value={time_shift}
        title="Time Shift"
      >
        <option>Choose an option</option>
        <option value="Morning Shift - PH TIME">Morning Shift - PH TIME</option>
        <option value="Afternoon Shift - PH TIME">
          Afternoon Shift - PH TIME
        </option>
        <option value="Night Shift - PH TIME">Night Shift - PH TIME</option>
      </SingleDropdownSelect>
      {/*  */}
      <RichTextbox
        label="Job Summary"
        removeButtons={[
          'paragraph',
          'bold',
          'underline',
          'italic',
          '|',
          'ul',
          'ol',
          '|',
          'link',
          '|',
          'undo',
          'redo',
        ]}
        content={description}
        setContent={handleDescription}
      />
      {/* employment type field needs options and state created */}
      <SingleDropdownSelect
        onChange={(e) => dispatch(setRecruiterId(e.target.value))}
        title="Recruiter"
        value={recruiter_id}
      >
        <option>Choose an option</option>
        {recruiters?.resp?.map((recruiter) => {
          if (recruiter?.Recruiter?.name) {
            return (
              <option
                key={recruiter?.Recruiter?.user_id}
                value={recruiter?.Recruiter?.user_id}
              >
                {recruiter?.Recruiter?.name}
              </option>
            )
          }
          return null
        })}
      </SingleDropdownSelect>
      {/* employment type field needs options and state created */}

      <SingleDropdownSelect
        onChange={handleClientChange}
        title="Client"
        value={client}
      >
        <option>Choose an option</option>
        {companies?.map((item) => (
          <option key={item.id} value={item.name}>
            {item.name}
          </option>
        ))}
      </SingleDropdownSelect>

      <SingleDropdownSelect
        onChange={handleContactChange}
        title="Point of Contact"
        value={poc_id}
      >
        <option>Choose an option</option>
        {contacts?.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </SingleDropdownSelect>
      {/* Toggle button */}
      <JobToggleButton
        value={requires_coaching}
        onClick={() => dispatch({ type: SET_REQUIRES_COACHING })}
        label="This listing requires coaching"
        mt=".875rem"
        mb="1.5rem"
      />
      <JobToggleButton
        value={priority}
        onClick={() => dispatch({ type: SET_PRIORITY })}
        label="Set as priority"
        mt=".875rem"
        mb="1.5rem"
      />

      {/*  submit button */}
      <Flex width="100%" justify="flex-end">
        <DashboardButton
          text="Next Step"
          onClick={handleSubmit}
          rounded
          notIcon
        />
      </Flex>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Flex>
  )
}

export default JobDetails
