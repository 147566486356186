import React from 'react'

import styled from 'styled-components'
import Tags from '../../../../components/Tags'

const JobDetail = ({
  title,
  jobType,
  description,
  timeShift,
  status,
  activeVacancy,
  vacancyPooling,
  client,
  requiresCoaching,
  priority,
  postedBy,
  contact,
}) => {
  return (
    <StyledGrid>
      <StyledJobDetails>
        <div className="title">Status</div>
        <div className="content">
          <Tags text={status} rounded status={status} />
        </div>
      </StyledJobDetails>
      <StyledJobDetails>
        <div className="title">Title</div>
        <div className="content">{title}</div>
      </StyledJobDetails>
      <StyledJobDetails>
        <div className="title">Engagement type</div>
        <div className="content">{jobType}</div>
      </StyledJobDetails>
      <StyledJobDetails>
        <div className="title">Time Shift</div>
        <div className="content">{timeShift}</div>
      </StyledJobDetails>
      <StyledJobDetails>
        <div className="title">Recruiter</div>
        <div className="content">{postedBy}</div>
      </StyledJobDetails>
      <StyledJobDetails>
        <div className="title">Job Summary</div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </StyledJobDetails>
      <StyledJobDetails>
        <div className="title">Active Vacancy</div>
        <div className="content">{activeVacancy}</div>
      </StyledJobDetails>
      {vacancyPooling && (
        <StyledJobDetails>
          <div className="title">Vacancy Pooling</div>
          <div className="content">{vacancyPooling}</div>
        </StyledJobDetails>
      )}
      <StyledJobDetails>
        <div className="title">Client</div>
        <div className="content">{client}</div>
      </StyledJobDetails>
      <StyledJobDetails>
        <div className="title">Point of Contact</div>
        <div className="content">{contact}</div>
      </StyledJobDetails>
      <StyledJobDetails>
        <div className="title">Requires Coaching</div>
        <div className="content">
          {requiresCoaching === 1 ? 'True' : 'False'}
        </div>
      </StyledJobDetails>
      <StyledJobDetails>
        <div className="title">Marked as priority</div>
        <div className="content">
          {priority === 1 ? 'True' : 'False'}
        </div>
      </StyledJobDetails>
    </StyledGrid>
  )
}

export default JobDetail

export const StyledJobDetails = styled.div`
  display: grid;
  grid-template-columns: 130px 1fr;
  gap: 1rem;

  .title {
    font-size: 1rem;
    font-weight: 300;
    color: #676e92;
  }

  .content {
    font-size: 1rem;
    font-weight: 300;
    color: #0e1324;

    /* Styles for Job Summary - Content from Jodit 
      Data is populated from MS Word/Google Docs which creates inline styles and 
      pasted on Jodit Editor
    */
    span {
      font-size: 16px !important;
      font-family: 'Lato', sans-serif !important;
      font-weight: 300 !important;
    }

    span[style*='font-weight: 700;'] {
      font-weight: 700 !important;
    }

    /* For content pasted from MS Word  */
    .MsoNormal {
      strong {
        span {
          font-weight: 700 !important;
        }
      }
    }
  }

  .flex {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
`

export const StyledGrid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1.5rem;
`
