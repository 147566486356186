import React from 'react'
import styled from 'styled-components'

import CreatableSelect from 'react-select/creatable'

import Select, { components } from 'react-select'

const Menu = (props) => {
  const optionSelectedLength = props.getValue().length || 0
  return (
    <components.Menu {...props}>
      {optionSelectedLength < 5 ? (
        props.children
      ) : (
        <div style={{ margin: 15 }}>Five Skills Completed</div>
      )}
    </components.Menu>
  )
}

const DropdownSelect = ({
  onKeyDown,
  options,
  selectedOption,
  setSelectedOption,
  title,
  placeholder,
}) => {
  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 5

  return (
    <>
      <StyledInputGroup>
        <p>{title}</p>
        <CreatableSelect
          onKeyDown={onKeyDown}
          components={{ Menu }}
          isMulti
          isValidNewOption={isValidNewOption}
          value={selectedOption}
          options={options}
          onChange={(value) => setSelectedOption(value)}
          placeholder={placeholder}
        />
      </StyledInputGroup>
    </>
  )
}

export const SingleDropdownSelect = ({
  onChange,
  onKeyDown,
  name,
  selectedOption,
  value,
  title,
  children,
  disabled,
  maxw,
  className,
}) => {
  return (
    <StyledInputGroup maxw={maxw}>
      <p>{title}</p>

      <select
        disabled={disabled}
        value={selectedOption || value}
        onKeyDown={onKeyDown}
        onChange={onChange}
        className={`select ${className}`}
        name={name}
        id={name}
        placeholder="Choose One"
      >
        {children}
      </select>
    </StyledInputGroup>
  )
}

export const MultiDropDownSelect = ({
  onKeyDown,
  options,
  selectedOption,
  setSelectedOption,
  title,
  placeholder,
}) => {
  return (
    <>
      <StyledInputGroup>
        <p>{title}</p>
        <Select
          onKeyDown={onKeyDown}
          isMulti
          value={selectedOption}
          options={options}
          onChange={(value) => setSelectedOption(value)}
          placeholder={placeholder}
        ></Select>
      </StyledInputGroup>
    </>
  )
}

export default DropdownSelect

const StyledInputGroup = styled.div`
  width: 100%;
  max-width: ${(props) => props.maxw || 'auto'};
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  &.inline {
    flex-direction: row;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }

  p {
    font-size: 0.75rem;
    font-weight: 300;
    color: #0e1324;
    letter-spacing: 0.03rem;
    margin-bottom: 0.25rem;
  }

  .css-1s2u09g-control {
    border: 1px solid #aeb3c9;

    .css-14el2xx-placeholder {
      font-size: 0.75rem;
      color: #0e1324;
    }
  }

  .select {
    width: 100%;
    height: 2.5rem;
    border: 1px solid #aeb3c9;
    outline: none;
    font-size: 0.7rem;
    font-weight: 300;
    color: #6e7490;
    padding: 0 0.35rem;
  }
`
