/* eslint-disable camelcase */
import moment from 'moment'
import React from 'react'
import { NavLink } from 'react-router-dom'

import styled from 'styled-components'
import Tags from '../../../../components/Tags'

const JobList = ({
  title,
  status,
  priority,
  min_salary,
  max_salary,
  created_at,
  id,
  active_vacancy,
  vacancy_for_pooling,
  interviews,
}) => {
  const total_accepted_vacancies = interviews.filter(
    (e) =>
      e.job_id === id &&
      e.interviewStatus === 'Accepted' &&
      e.stages === 'closed'
  )
  const total_vacancies_pooling = interviews.filter(
    (e) =>
      e.job_id === id &&
      vacancy_for_pooling &&
      e.stages !== 'applicant_screening' &&
      e.rejection_type_id !== 1 &&
      e.interviewStatus !== 'Accepted'
  )

  return (
    <JobListCard
      as={NavLink}
      className={({ isActive }) => (isActive ? 'active' : '')}
      to={`/job-listing/${id}`}
    >
      <div className="flex">
        <h2 className="title">{title}</h2>
        <div className="tags">
          {priority === 1 &&
            <Tags text={'priority'} status={'active'} rounded />
          }
          <Tags text={status} status={status} rounded />
        </div>
      </div>
      <p className="amount">
        PHP {parseFloat(min_salary).toLocaleString()} - PHP{' '}
        {parseFloat(max_salary).toLocaleString()}
      </p>
      <div className="vacancies">
        <div className="vacancy">
          <p className="vacancy_type">Active Vacancy</p>
          <p className="total_vacancies">{`${total_accepted_vacancies.length}/${active_vacancy || 0
            }`}</p>
        </div>
        {vacancy_for_pooling ? (
          <div className="vacancy">
            <p className="vacancy_type">Vacancy for Pooling</p>
            <p className="total_vacancies">{`${total_vacancies_pooling.length}/${vacancy_for_pooling}`}</p>
          </div>
        ) : null}
      </div>
      <p className="datetime">
        Created at {moment(created_at).format('MMM Do YY')}
      </p>
    </JobListCard>
  )
}

export default JobList

const JobListCard = styled.div`
  width: 100%;
  padding: 1rem;
  background: #fff;
  border: 1px solid #eeeef7;
  text-decoration: none;

  &.active,
  &:active,
  &:hover {
    border: 1px solid #6e83ce;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tags{
      float: right;
    }
  }

  .vacancies {
    margin-bottom: 0.5rem;
    .vacancy {
      display: flex;
      font-size: 14px;

      .vacancy_type {
        font-weight: 600;
        margin: 0;
        padding: 0;
      }

      .total_vacancies {
        margin: 0 5px;
        color: #31374f;
      }
    }
  }

  .title,
  .amount {
    font-size: 0.875rem;
    font-weight: 500;
    color: #31374f;
    text-decoration: none;
  }

  .amount {
    font-weight: 300;
    padding: 0;
    padding-bottom: 0;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .datetime {
    font-size: 0.75rem;
    font-weight: 300;
    color: #676e92;
    padding: 0;
    padding-bottom: 0;
    margin: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
`
