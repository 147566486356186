import React from 'react'

import { IoMdClose } from 'react-icons/io'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import JobDetailsUpdate from './JobDetailsUpdate'
import ModalSidebar from './ModalSidebar'
import PaymentUpdate from './PaymentUpdate'
import QualificationUpdate from './QualificationUpdate'

import { isBoolean } from '../../../../../utils/stateHelpers'

const UpdateJob = ({ id, data, setOpenModal }) => {
  const { updateStatusTab } = useSelector((state) => state.jobPosting)
  const { status } = data

  return (
    <StyledUpdateJob>
      <div className="modal_overlay" onClick={() => setOpenModal(false)} />
      <div className="modal_container">
        <div className="modal_header">
          {status === 'closed' ? 'View Listing' : 'Edit Listing'}
        </div>

        <div onClick={() => setOpenModal(false)} className="icon_wrapper">
          <IoMdClose />
        </div>

        <div className="modal_body">
          <ModalSidebar />
          {updateStatusTab === 'Job Details' && (
            <JobDetailsUpdate
              title={data?.title}
              description={data?.description}
              job_type={data?.job_type}
              id={id}
              active_vacancy={data?.active_vacancy}
              vacancy_for_pooling={data?.vacancy_for_pooling}
              time_shift={data?.time_shift}
              posted_by={data?.posted_by}
              poc_id={data?.poc_id}
              point_of_contacts={data?.pointOfContacts}
              client={data?.client}
              company_id={data?.company_id}
              requires_coaching={isBoolean(data?.requires_coaching)}
              setOpenModal={setOpenModal}
              status={data?.status}
              priority={isBoolean(data?.priority)}
            />
          )}

          {updateStatusTab === 'Payment and Benefits' && (
            <PaymentUpdate
              id={id}
              min_salary={data?.min_salary}
              max_salary={data?.max_salary}
              salary_basis={data?.salary_basis}
              benefits={data?.benefits}
              setOpenModal={setOpenModal}
              status={data?.status}
            />
          )}
          {updateStatusTab === 'Qualifications' && (
            <QualificationUpdate
              experience={data?.experience}
              id={id}
              technical_skills={data?.technical_skills}
              availability_to_start={data?.availability_to_start}
              education={data?.education}
              software={data?.software}
              hardware={data?.hardware_requirement}
              language={data?.language}
              certification={data?.certification}
              personal_skills={data?.personal_skills}
              time_shift={data?.time_shift}
              shift_availability={data?.shift_availability}
              setOpenModal={setOpenModal}
              status={data?.status}
            />
          )}
        </div>
      </div>
    </StyledUpdateJob>
  )
}

export default UpdateJob

const StyledUpdateJob = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(71, 86, 116, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon_wrapper {
    position: absolute;
    top: 1.5rem;
    right: 3rem;
  }

  .modal_overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .modal_container {
    width: 100%;
    max-width: 929px;
    background: #fff;
    min-height: 300px;
    padding: 1.5rem 2.5rem;
    position: relative;
    z-index: 2;

    .modal_header {
      font-size: 1.5rem;
      font-weight: 300;
      color: #31374f;
    }

    .modal_body {
      width: 100%;
      display: grid;
      grid-template-columns: 200px 1fr;
      gap: 2rem;
      margin-top: 1.5rem;
    }
  }
`
