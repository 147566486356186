import React from 'react'
import { GrFormClose } from 'react-icons/gr'
import { useDispatch } from 'react-redux'

import styled from 'styled-components'

import avatar from '../../../assets/icons/avatar.png'
import { setModal } from '../../../store/actions/listsActions'
import { P } from '../../../style/Typography.style'

const ModalHead = ({ name = '', role = '' }) => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(setModal(''))
  }
  return (
    <StyledModalHead>
      <div className="details">
        <img src={avatar} alt="user icon" />
        <div className="name">
          <P weight="500" size="1.15rem">
            {name.toUpperCase()}
          </P>
          <P size="0.875rem">Role: {role}</P>
        </div>
      </div>
      <div onClick={() => handleClose()} className="modal-close-wrapper">
        <GrFormClose className="modal-close" />
      </div>
    </StyledModalHead>
  )
}

export default ModalHead

const StyledModalHead = styled.div`
  width: 100%;
  border-bottom: 1px solid #dfdfe9;
  margin-bottom: 1.5rem;
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .details {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .modal-close-wrapper {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: #f5f5f5;
    }

    .modal-close {
      font-size: 1.5rem;
    }
  }
`
