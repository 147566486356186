import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import ModalHead from '../../../../components/dashboard/modal/ModalHead'
import { P } from '../../../../style/Typography.style'

import {
  showApplicants,
  setModal,
} from '../../../../store/actions/listsActions'
import { updateTalents } from '../../../../store/actions/talentActions'

import useMutation from '../../../../hooks/useMutation'

const RejectApplicant = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const [reason, setReason] = useState('')

  const { talents, talent } = useSelector((state) => state.talents)
  const { applicant, moveList, applicants, selectedList } = useSelector(
    (state) => state.lists
  )
  const { data, handleRequest, loading, success, error } = useMutation()

  function handleRejectApplicant() {
    if (reason === '') {
      return addToast('Please input a reason', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    handleRequest(
      `/${applicant.applicant_id}/reject`,
      { message: reason },
      'PUT'
    )
  }

  useEffect(() => {
    if (data) {
      const filteredApplicants = applicants.filter(
        (user) => user.applicant_id !== applicant.applicantId
      )

      const filteredTalents = talents.filter(
        (talent) => talent.id !== applicant.applicantId
      )

      const selectedTalent = talent
      selectedTalent.listName = moveList.list_name

      const changedList = selectedList
      const updatedList = moveList

      changedList.numbersOfApplicantInList -= 1
      updatedList.numbersOfApplicantInList += 1

      dispatch(updateTalents([selectedTalent, ...filteredTalents]))
      dispatch(showApplicants(filteredApplicants))

      dispatch(setModal(''))

      addToast(
        `${applicant?.applicantName
        } has been declined and removed from ${selectedList?.list_name
        }`,
        {
          appearance: 'success',
          autoDismiss: true,
        }
      )
    }
  }, [success])

  useEffect(() => {
    if (error) {
      addToast(
        error?.response?.data?.message ||
          error?.response?.message ||
          'Something went wrong',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  return (
    <MainModal
      buttonText="Reject Applicant"
      loading={loading}
      onClick={handleRejectApplicant}
      error
      header={
        <ModalHead
          name={applicant.applicant_name}
          role={applicant.listName}
        />
      }
    >
      {/*  */}
      <TextAreaContainer>
        <P>
          Reason for Rejection <span style={{ color: '#DC2929' }}>*</span>
        </P>
        <textarea
          onChange={(e) => setReason(e.target.value)}
          value={reason}
        ></textarea>
      </TextAreaContainer>
    </MainModal>
  )
}

export default RejectApplicant

const TextAreaContainer = styled.div`
  margin-top: 2rem;

  textarea {
    width: 100%;
    height: 222px;
    border: 1px solid #aeb3c9;
    margin-top: 0.875rem;
    outline: none;
    border-radius: none;
    padding: 0.75rem;
    font-size: 0.875rem;
    font-weight: 300;

    &:focus,
    &:hover {
      border: 1px solid #1a1a1a;
    }
  }
`
