import React, { useEffect, useState } from 'react'

import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import ListSelector from '../../../../components/dashboard/controls/ListSelector'
import TalentCardPopList from '../../../../components/dashboard/TalentPool/TalentCardPopList'
import TalentCardPopout from '../../../../components/dashboard/TalentPool/TalentCardPopout'

import useMutation from '../../../../hooks/useMutation'

import { setModal } from '../../../../store/actions/listsActions'
import { setInterviews } from '../../../../store/actions/recruitersActions'

const ActionsMenu = ({ setShowMenu }) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [menuState, setMenuState] = useState('')
  const [updatedRecruiter, setUpdatedRecruiter] = useState({
    id: '',
    name: '',
  })

  const { selectedInterview, recruiters, interviews, selectedStage } =
    useSelector((state) => state.requisitions)

  const { data, error, handleRequest, loading, success } = useMutation()

  function handleChangeRecruiter(e, recruiter) {
    e.stopPropagation()
    handleRequest(
      `/interviews/${selectedInterview.id}/update`,
      {
        recruiter_id: recruiter.Recruiter.id.toString(),
        applicant_id: selectedInterview.applicant_id.toString(),
      },
      'PUT'
    )
    setUpdatedRecruiter({
      id: recruiter.Recruiter.id,
      name: recruiter.Recruiter.name,
    })
  }

  // check if request was successful.
  useEffect(() => {
    if (data) {
      const filteredInterview = interviews.filter(
        (interview) => interview.id !== selectedInterview.id
      )

      const updatedInterview = selectedInterview
      updatedInterview.recruiter_id = updatedRecruiter.id
      updatedInterview.recruiterName = updatedRecruiter.name

      dispatch(setInterviews([updatedInterview, ...filteredInterview]))

      addToast(data.message, {
        appearance: 'success',
        autoDismiss: true,
      })

      setMenuState('')
      setShowMenu(false)
    }
  }, [success])

  // check if request was unsuccessful.
  useEffect(() => {
    if (error) {
      addToast(
        error.message ||
          error.data.message ||
          error.response.data.message ||
          'something went wrong.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowMenu(false)
      }}
    >
      <TalentCardPopout maxW="199px" width="199px" top="0" left="76%">
        {/* menu state to show the list of menus and select the one you want to move the applicant too */}
        {menuState === 'recruiter' && (
          <TalentCardPopout
            maxW="240px"
            maxH="300px"
            overflowY="auto"
            width="240px"
            top="0"
            left="103%"
          >
            {recruiters?.map((recruiter) => {
              if (recruiter?.Recruiter?.name) {
                return (
                  <ListSelector
                    key={recruiter?.id}
                    text={recruiter?.Recruiter?.name}
                    active={
                      recruiter?.Recruiter?.id ===
                      selectedInterview?.recruiterId
                    }
                    onClick={(e) => handleChangeRecruiter(e, recruiter)}
                    loading={loading}
                  />
                )
              }

              return null
            })}
          </TalentCardPopout>
        )}

        {![
          'applicant_screening',
          'Skill_assesment',
          'client_profiling',
          'offer',
        ].includes(selectedStage) && (
          <TalentCardPopList
            active={menuState === 'list'}
            onClick={(e) => {
              e.stopPropagation()
              dispatch(setModal('changeRecruiter'))
              setMenuState('')
            }}
            text="Change Interviewer"
          />
        )}
        {['first_interview'].includes(selectedStage) && (
          <TalentCardPopList
            active={menuState === 'list'}
            onClick={(e) => {
              e.stopPropagation()
              dispatch(setModal('addAskingSalary'))
              setMenuState('')
            }}
            text="Add asking salary"
          />
        )}
        {!['offer', 'closed'].includes(selectedStage) && (
          <TalentCardPopList
            active={menuState === 'list'}
            onClick={(e) => {
              e.stopPropagation()
              dispatch(setModal('changeVacancy'))
            }}
            text="Change Vacancy"
          />
        )}
        {
          <TalentCardPopList
            onClick={(e) => {
              e.stopPropagation()
              dispatch(setModal('applicantDetails'))
            }}
            text="View Applicant Activity"
          />
        }
        {
          <TalentCardPopList
            onClick={(e) => {
              e.stopPropagation()
              dispatch(setModal('rejectApplicants'))
            }}
            text="Reject Applicant"
          />
        }
        {['second_interview'].includes(selectedStage) && (
          <TalentCardPopList
            onClick={(e) => {
              e.stopPropagation()
              dispatch(setModal('changeInterviewLink'))
            }}
            text="Change Interview Link"
          />
        )}
      </TalentCardPopout>
    </OutsideClickHandler>
  )
}

export default ActionsMenu
