import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'
import RadioButton from '../../../../components/dashboard/controls/RadioButton'
import MainModal from '../../../../components/dashboard/modal/MainModal'
import ModalHead from '../../../../components/dashboard/modal/ModalHead'
import axiosInstance from '../../../../services/config/AxiosIntance'
import { setModal } from '../../../../store/actions/listsActions'
import { setInterviews, setUpdatedStage } from '../../../../store/actions/recruitersActions'
import { P } from '../../../../style/Typography.style'

const RejectApplicants = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const [rejectStatus, setRejectStatus] = useState('reject')
  const [reason, setReason] = useState('')
  const [loading, setLoading] = useState(false)

  const { selectedInterview, interviews } = useSelector(
    (state) => state.requisitions
  )

  async function handleRejectApplicant(e) {
    e.preventDefault()

    if (rejectStatus === 'reject' && reason === '') {
      return addToast('Please input a reason', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    setLoading(true)

    const reqBody = {
      message: reason,
      applicant_id: selectedInterview.applicant_id.toString(),
      stage_id: selectedInterview.stage_id,
      role: selectedInterview.role,
      job_id: selectedInterview?.job_id?.toString(),
      applicant_email: selectedInterview.applicantEmail,
      applicant_name: selectedInterview.applicantName
    }

    if (rejectStatus === 'reject') {
      try {
        await axiosInstance.put(
          `/interviews/${selectedInterview.id}/reject/reject`,
          reqBody
        )

        const filteredInterviews = interviews.filter(
          (interview) => interview.id !== selectedInterview.id
        )

        dispatch(setInterviews(filteredInterviews))

        addToast(
          `${selectedInterview.applicantName} is rejected and moved to Closed stage`,
          {
            appearance: 'info',
            autoDismiss: true,
          }
        )
        dispatch(setUpdatedStage('offer'))
        setReason('')
        dispatch(setModal(''))
        setLoading(false)
      } catch (error) {
        setLoading(false)
        addToast(
          error?.response?.data?.message ||
          error?.response?.data ||
          'Something went wrong',
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
      }
    } else {
      try {
        await axiosInstance.put(
          `/interviews/${selectedInterview.id}/reject/move`,
          reqBody
        )

        const filteredInterviews = interviews.filter(
          (interview) => interview.id !== selectedInterview.id
        )

        dispatch(setInterviews(filteredInterviews))

        addToast(
          `${selectedInterview.applicantName} is moved back to Talent Pool`,
          {
            appearance: 'info',
            autoDismiss: true,
          }
        )
        dispatch(setUpdatedStage('offer'))
        dispatch(setModal(''))
        setLoading(false)
      } catch (error) {
        addToast(
          error?.response?.data?.message ||
          error?.response?.data ||
          'Something went wrong',
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
        setLoading(false)
      }
    }
  }
  return (
    <MainModal
      buttonText="Reject Applicant"
      loading={loading}
      onClick={handleRejectApplicant}
      error
      header={
        <ModalHead
          name={selectedInterview.applicantName}
          role={selectedInterview.role}
        />
      }
    >
      <Grid>
        <RadioButton
          name="rejectStatus"
          value={rejectStatus}
          checked={rejectStatus === 'reject'}
          onChange={() => setRejectStatus('reject')}
          id="reject"
        />

        <div>
          <P size="1.25rem" as="label" htmlFor="reject">
            Reject Applicant
          </P>
          <P as="label" htmlFor="reject">
            This will remove the applicant from its respective list and moved
            back to the talent pool with a ‘declined’ tag
          </P>
        </div>
      </Grid>
      <Grid>
        <RadioButton
          name="rejectStatus"
          checked={rejectStatus === 'move'}
          value={rejectStatus}
          onChange={() => setRejectStatus('move')}
          id="move"
        />

        <div>
          <P
            size="1.25rem"
            as="label"
            style={{ display: 'block' }}
            htmlFor="move"
          >
            Move to Talent Pool
          </P>
          <P as="label" htmlFor="move">
            This will move applicant back to the talent pool with no ‘declined’
            tag
          </P>
        </div>
      </Grid>

      {/*  */}
      {rejectStatus === 'reject' && (
        <TextAreaContainer>
          <P>
            Reason for Rejection <span style={{ color: '#DC2929' }}>*</span>
          </P>
          <textarea
            onChange={(e) => setReason(e.target.value)}
            value={reason}
          ></textarea>
        </TextAreaContainer>
      )}
    </MainModal>
  )
}

export default RejectApplicants

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
`
const TextAreaContainer = styled.div`
  margin-top: 2rem;

  textarea {
    width: 100%;
    height: 222px;
    border: 1px solid #aeb3c9;
    margin-top: 0.875rem;
    outline: none;
    border-radius: none;
    padding: 0.75rem;
    font-size: 0.875rem;
    font-weight: 300;

    &:focus,
    &:hover {
      border: 1px solid #1a1a1a;
    }
  }
`
