/* eslint-disable camelcase */
import React, { memo, useState, useEffect } from 'react'

import moment from 'moment'

import styled from 'styled-components'
import { P } from '../../../style/Typography.style'

import { FaSmile } from 'react-icons/fa'

import { sortByNewestFirst } from '../../../constant/sortBy'

import { useSelector, useDispatch } from 'react-redux'

import EditNote from './EditNote'

import { useToasts } from 'react-toast-notifications'

import useMutation from '../../../hooks/useMutation'

import { OPEN_ACTIVITIES_MODAL } from '../../../store/actions/types'

const Notes = ({ data, loadingData, refetch }) => {
  const dispatch = useDispatch()

  const { updatedNote } = useSelector((store) => store.requisitions)
  const { user } = useSelector((store) => store.auth)

  const [editNoteID, setEditNoteID] = useState(null)

  const { addToast } = useToasts()

  const { handleUpdateRequest, loading, success, error } = useMutation()

  const { activities } = useSelector((state) => state.modal)

  if (loadingData) return <P>Loading...</P>

  const parser = (note, noteID, note_author) => {
    try {
      return JSON.parse(note)
    } catch (error) {
      console.log(error)
    }
  }

  const handleToggleEditNote = (noteID) => {
    setEditNoteID((editNoteID) => noteID)
  }

  const handleEditNotes = () => {
    const note = data?.resp?.filter((x) => x.id === editNoteID)[0]
    const newNote = {
      id: note?.id,
      applicant_id: note?.applicant_id?.toString(),
      note_author_id: note?.note_author_id?.toString(),
      note_author: note?.note_author?.toString(),
      notes: updatedNote.notes,
    }
    handleUpdateRequest('/update-note', newNote)
    handleToggleEditNote(null)
  }

  useEffect(() => {
    if (!loading) refetch()
  }, [loading])

  useEffect(() => {
    if (success) {
      addToast('Note updated successfully.', { appearance: 'success' })

      dispatch({ type: OPEN_ACTIVITIES_MODAL, payload: 'notes' })
    }
  }, [success])

  useEffect(() => {
    if (error) {
      addToast(error?.response?.data?.message || 'Something went wrong', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [error])

  return (
    <StyledNotes activities={activities}>
      {data && data.resp.length > 0 ? (
        data.resp
          .sort(sortByNewestFirst)
          .map(
            ({
              created_at,
              updated_at,
              id: noteID,
              note_author,
              notes,
              note_author_id,
            }) => (
              <StyledNote key={noteID}>
                <FaSmile className="avatar" />
                <div className="details_section">
                  <div className="details_leftarea">
                    <P size="0.75rem" weight="500">
                      {note_author}
                    </P>
                    <P size="0.75rem" weight="300">
                      {created_at !== updated_at && <span>Edited: </span>}
                      {created_at === updated_at && <span>Created: </span>}
                      {moment(updated_at).format('MMMM Do, YYYY')} {' at '}
                      {moment(updated_at).format('h:mm A')}
                    </P>
                    <div>
                      {editNoteID !== noteID && (
                        <P
                          className="pContent"
                          marginTop="0.75rem"
                          size="0.75rem"
                          weight="300"
                          dangerouslySetInnerHTML={{
                            __html: parser(notes, noteID, note_author),
                          }}
                        />
                      )}
                      {editNoteID !== noteID && user.id === note_author_id && (
                        <div className="poc_form_icons">
                          <BorderlessButton
                            id="Update"
                            onClick={(id) => handleToggleEditNote(noteID)}
                          >
                            Edit
                          </BorderlessButton>
                        </div>
                      )}
                      {editNoteID === noteID && (
                        <EditNote id={noteID} data={data} />
                      )}
                      {editNoteID === noteID && (
                        <div className="details_rightarea">
                          <BorderlessButton id="Save" onClick={handleEditNotes}>
                            Save
                          </BorderlessButton>
                          <BorderlessButton
                            id="Cancel"
                            onClick={(id) => handleToggleEditNote(null)}
                          >
                            Cancel
                          </BorderlessButton>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </StyledNote>
            )
          )
      ) : (
        <P>No notes to display at the moment</P>
      )}
    </StyledNotes>
  )
}

export default memo(Notes)

export const StyledNotes = styled.section`
  width: 100%;
  padding: 0 0 1rem 0;
  min-height: 60px;

  .avatar {
    color: #ff7b9b;
    font-size: 36px;
  }

  .pContent {
    word-break: break-word;
  }
`

const StyledNote = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 1rem;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`

export const BorderlessButton = styled.button`
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: #858aa0;
  background: none;
  border: none;
  outline: none;
  float: left;
  padding: 0.3rem;

  &:hover {
    color: #1a1a1a;
  }
`
