import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'
import moment from 'moment'
import { useToasts } from 'react-toast-notifications'

import Table from '../../../../components/Table'
import Flex from '../../../../components/layouts/Flex'
import TableAction from './TableAction'
import axiosInstance from '../../../../services/config/AxiosIntance'

import { P } from '../../../../style/Typography.style'

const ApplicantScreening = ({ onRowClick }) => {
  const { addToast } = useToasts()
  const { filters, updatedStage } = useSelector((state) => state.requisitions)

  const [interviews, setInterviews] = useState([])
  const [totalPage, setTotalPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(true)
  const [rowCount, setRowCount] = useState(0)

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type
  const userId = JSON.parse(currentUser)?.id
  const recruiterAssistant = !!JSON.parse(currentUser)?.recruiter_assistant

  const companyDetails =
    role === 'poc' && JSON.parse(currentUser)?.companyDetails

  const stage = 'applicant_screening'
  let url = `/interviews?stage=${stage}&searchTerm=${filters.search}&job_title=${filters.searchRole}&startDate=${filters.startDate}&endDate=${filters.endDate}&sort=${filters.sort}`

  if (!recruiterAssistant && role === 'recruiter') {
    url += `&recruiterId=${userId}`
  } else if (role === 'poc') {
    url += `&companyDetails=${JSON.stringify(companyDetails)}`
  }

  const fetchData = useCallback(async (url) => {
    setLoading(true)
    try {
      const result = await axiosInstance.get(url)

      const { data } = result

      setTotalPage(data?.resp?.pagination?.pageCount || 1)
      setCurrentPage(data?.resp?.pagination?.page || 1)
      setPageSize(data?.resp?.pagination?.pageSize || 10)
      setRowCount(data?.resp?.pagination?.rowCount || 0)

      setInterviews(data?.resp?.interviews || [])
      setLoading(false)
    } catch (err) {
      addToast(err?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
      })
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchData(url)
  }, [filters])

  useEffect(() => {
    if (updatedStage === 'applicant_screening') fetchData(url)
  }, [updatedStage])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'actions',
        disableSortBy: true,
        Cell: ({ value }) => <TableAction value={value} />,
      },
      {
        Header: 'Applicant',
        accessor: 'applicantName',
      },
      {
        Header: 'Role',
        accessor: 'role',
        width: 10000,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Client',
        accessor: 'client',
      },
      {
        Header: 'Contact No.',
        accessor: 'contact',
      },
      {
        Header: 'Recruiter',
        accessor: 'recruiter',
      },
      {
        Header: 'Channel',
        accessor: 'channel',
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
        Cell: ({ value }) => (value ? moment(value).format('DD/MM/YYYY') : ''),
        sortType: 'datetime',
      },
      {
        Header: 'Last Updated',
        accessor: 'lastUpdated',
        Cell: ({ value }) => (value ? moment(value).format('DD/MM/YYYY') : ''),
        sortType: 'datetime',
      },
    ],
    []
  )

  const tableData = interviews.map(
    (item) =>
      ({
        actions: item,
        applicantName: item.applicantName || '',
        role: item.role || '',
        email: item.applicantEmail || '',
        client: item.client || '',
        contact: item.mobileNumber || '',
        recruiter: item.recruiterName || '',
        schedule:
          moment(item.interview_date).format('DD/MM/YYYY') +
            ' • ' +
            moment(item.interview_time).format('hh:mm a') || '',
        addedBy: item.added_by || '',
        channel: item.channel || '',
        dateCreated: item.created_at ? new Date(item.created_at) : null,
        lastUpdated: item.updated_at ? new Date(item.updated_at) : null,
      } || '')
  )

  return (
    <StyledApplicantScreening>
      {interviews?.length > 0 ? (
        <Table
          data={tableData}
          columns={columns}
          loading={loading}
          totalPage={totalPage}
          currentPage={currentPage}
          pageSize={pageSize}
          rowCount={rowCount}
          fetchData={fetchData}
          setNewPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          url={`${url}&pageNumber=${currentPage}&pageSize=${pageSize}`}
          noData="Applicant Screening is empty"
          height="calc(103vh - 20rem)"
          onClick={onRowClick}
        />
      ) : (
        <Flex
          height="calc(100vh - 15rem)"
          bg="#fff"
          justify="center"
          items="center"
        >
          <P>Applicant Screening is empty</P>
        </Flex>
      )}
    </StyledApplicantScreening>
  )
}

export default ApplicantScreening

const StyledApplicantScreening = styled.div`
  width: 100%;
  height: 100%;
`
