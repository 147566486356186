import React from 'react'

import styled from 'styled-components'
import { useSelector } from 'react-redux'

import DefaultTemplate from './template/DefaultTemplate'
import HTMLTemplate from './template/HTMLTemplate'

import CopyToClipboard from '../CopyToClipboard'

const Preview = ({ downloadRef }) => {
  const displayHTML = useSelector((state) => state.talents.displayHTML)

  return (
    <StyledPreview>
      {displayHTML ? (
        <>
          <CopyToClipboard
            text={displayHTML}
            message="Successfully copied HTML text to clipboard."
          />
          <HTMLTemplate />
        </>
      ) : (
        <DefaultTemplate downloadRef={downloadRef} />
      )}
    </StyledPreview>
  )
}

export default Preview

const StyledPreview = styled.div`
  width: 100%;
`
