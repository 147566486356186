import React from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'

const HTMLTemplate = () => {
  const htmlTemplate = useSelector((state) => state.talents.displayHTML)

  return <StyledHTMLTemplate>{htmlTemplate}</StyledHTMLTemplate>
}

export default HTMLTemplate

const StyledHTMLTemplate = styled.div`
  width: 100%;
  max-width: 1100px;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #aeb3c9;
  border-radius: 3px;
  word-wrap: break-word;
`
