import React, { useEffect, useState } from 'react'

import styled from 'styled-components'
import {
  CollapsibleComponent,
  CollapsibleHead,
  CollapsibleContent,
} from 'react-collapsible-component'
import { useDispatch, useSelector } from 'react-redux'
import { SlArrowDown, SlArrowUp } from 'react-icons/sl'

import FindProfileFilter from './filterComponents/FindProfileFilter'
import WorkExperienceFilter from './filterComponents/WorkExperienceFilter'
import SkillsFilter from './filterComponents/SkillsFilter'
import EducationFilter from './filterComponents/EducationFilter'
import HardwareFilter from './filterComponents/HardwareFilter'
import LocationFilter from './filterComponents/LocationFilter'

import {
  setFilterByPhoto,
  setQueryTerm,
  filterTalents,
  setSelectedPing,
  setDownloadMBPS,
  setuploadMBPS,
  setAllOfTheseWords,
  setJobTitle,
  setFilterByYears,
  setCompanyName,
  setProfessionalHeadlinesFilter,
  setSkillsFilter,
  setSoftSkillsFilter,
  setLevelOfEducation,
  setOS,
  setProcessor,
  setRAM,
  setSecondFilter,
  setCountryFilter,
  calculateActiveFilters,
  setBulkItems,
} from '../../../../store/actions/talentActions'

const CollapsibleFilter = () => {
  const [collapsibleHead, setCollapsibleHead] = useState({})
  const dispatch = useDispatch()
  const { activeFilters } = useSelector((state) => state.talents)

  const handleCollapsibleHead = (filter) => {
    setCollapsibleHead((prevState) => ({
      ...prevState,
      [filter]: !collapsibleHead[filter],
    }))
  }

  useEffect(() => {
    dispatch(filterTalents())
  }, [])

  const handleApplyFilters = () => {
    dispatch(filterTalents())
    dispatch(calculateActiveFilters())
    dispatch(
      setBulkItems({
        selectedAll: false,
      })
    )
  }
  const handleClearFilters = () => {
    // Find Profile
    dispatch(setFilterByPhoto(false))
    dispatch(setQueryTerm(''))
    dispatch(setSelectedPing(''))
    dispatch(setDownloadMBPS(''))
    dispatch(setuploadMBPS(''))
    dispatch(setAllOfTheseWords(''))

    // Work Experience
    dispatch(setJobTitle(''))
    dispatch(setFilterByYears([]))
    dispatch(setCompanyName(''))

    // Skills
    dispatch(setProfessionalHeadlinesFilter([]))
    dispatch(setSkillsFilter([]))
    dispatch(setSoftSkillsFilter([]))

    // Education
    dispatch(setLevelOfEducation(''))

    // Hardware
    dispatch(setOS([]))
    dispatch(setProcessor([]))
    dispatch(setRAM([]))

    // Location
    dispatch(setSecondFilter(''))
    dispatch(setCountryFilter(''))

    dispatch(filterTalents())
    dispatch(calculateActiveFilters())
    dispatch(
      setBulkItems({
        selectedAll: false,
      })
    )
  }
  return (
    <CollapsibleComponent>
      <StyledHead onClick={() => handleCollapsibleHead('findProfile')}>
        <CollapsibleHead isExpanded={collapsibleHead?.findProfile}>
          <p>Find Profiles with...</p>
          {activeFilters?.findProfile > 0 && (
            <span>{activeFilters?.findProfile}</span>
          )}
          {collapsibleHead?.findProfile ? <SlArrowUp /> : <SlArrowDown />}
        </CollapsibleHead>
      </StyledHead>
      <StyledCollapsibleContent isExpanded={collapsibleHead?.findProfile}>
        <FindProfileFilter />
      </StyledCollapsibleContent>

      <StyledHead onClick={() => handleCollapsibleHead('workExperience')}>
        <CollapsibleHead isExpanded={collapsibleHead?.workExperience}>
          <p>Work Experience</p>
          {activeFilters?.workExperience > 0 && (
            <span>{activeFilters?.workExperience}</span>
          )}
          {collapsibleHead?.workExperience ? <SlArrowUp /> : <SlArrowDown />}
        </CollapsibleHead>
      </StyledHead>
      <StyledCollapsibleContent isExpanded={collapsibleHead?.workExperience}>
        <WorkExperienceFilter />
      </StyledCollapsibleContent>

      <StyledHead onClick={() => handleCollapsibleHead('skill')}>
        <CollapsibleHead isExpanded={collapsibleHead?.workExperience}>
          <p>Skills</p>
          {activeFilters?.skills > 0 && <span>{activeFilters?.skills}</span>}
          {collapsibleHead?.skill ? <SlArrowUp /> : <SlArrowDown />}
        </CollapsibleHead>
      </StyledHead>
      <StyledCollapsibleContent isExpanded={collapsibleHead?.skill}>
        <SkillsFilter />
      </StyledCollapsibleContent>

      <StyledHead onClick={() => handleCollapsibleHead('education')}>
        <CollapsibleHead isExpanded={collapsibleHead?.education}>
          <p>Education</p>
          {activeFilters?.education > 0 && (
            <span>{activeFilters?.education}</span>
          )}
          {collapsibleHead?.education ? <SlArrowUp /> : <SlArrowDown />}
        </CollapsibleHead>
      </StyledHead>
      <StyledCollapsibleContent isExpanded={collapsibleHead?.education}>
        <EducationFilter />
      </StyledCollapsibleContent>

      <StyledHead onClick={() => handleCollapsibleHead('hardware')}>
        <CollapsibleHead isExpanded={collapsibleHead?.hardware}>
          <p>Hardware</p>
          {activeFilters?.hardware > 0 && (
            <span>{activeFilters?.hardware}</span>
          )}
          {collapsibleHead?.hardware ? <SlArrowUp /> : <SlArrowDown />}
        </CollapsibleHead>
      </StyledHead>
      <StyledCollapsibleContent isExpanded={collapsibleHead?.hardware}>
        <HardwareFilter />
      </StyledCollapsibleContent>

      <StyledHead onClick={() => handleCollapsibleHead('location')}>
        <CollapsibleHead isExpanded={collapsibleHead?.location}>
          <p>Location</p>
          {activeFilters?.location > 0 && (
            <span>{activeFilters?.location}</span>
          )}
          {collapsibleHead?.location ? <SlArrowUp /> : <SlArrowDown />}
        </CollapsibleHead>
      </StyledHead>
      <StyledCollapsibleContent isExpanded={collapsibleHead?.location}>
        <LocationFilter />
      </StyledCollapsibleContent>

      <ApplyFilterButton onClick={handleApplyFilters}>
        Apply Filters
      </ApplyFilterButton>
      <ClearFilterButton onClick={handleClearFilters}>
        Clear Filters
      </ClearFilterButton>
    </CollapsibleComponent>
  )
}

export default CollapsibleFilter

const StyledHead = styled.div`
  .accordion-head {
    display: flex;
    justify-content: space-between;
    background: none;
    color: #1a1a1a;
    padding-left: 0;
  }
`

const StyledCollapsibleContent = styled(CollapsibleContent)`
  overflow: visible;
`

const ApplyFilterButton = styled.button`
  height: 2.1rem;
  width: 100%;
  background: #1a1a1a;
  border: none;
  outline: none;
  border-radius: 3px;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 400;
  cursor: pointer;
`

const ClearFilterButton = styled.button`
  height: 2.1rem;
  width: 100%;
  background: #ffffff;
  border: none;
  outline: none;
  border-radius: 3px;
  color: #31374f;
  font-size: 0.75rem;
  font-weight: 400;
  cursor: pointer;
`
