import React from 'react'
import Pagination from 'react-responsive-pagination'
import styled from 'styled-components'

const PageButtons = ({ totalPages, currentPage, onPageClick, maxWidth }) => {
  return (
    <StyledPagination>
      <Pagination
        total={totalPages || 1}
        current={currentPage || 1}
        onPageChange={onPageClick}
        maxWidth={maxWidth}
        pageItemClassName="page-item"
        ariaCurrentAttr={false}
        previousLabel="<"
        ariaPreviousLabel="<"
        nextLabel=">"
        ariaNextLabel=">"
      />
    </StyledPagination>
  )
}

const StyledPagination = styled.div`
  .page-item {
    height: 2rem;
    width: 2rem;
    max-width: 200px;

    .page-link {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      display: none;
    }
  }
`

export default PageButtons
