import { jobs } from '../types/jobListing'
import axiosInstance from '../../services/config/AxiosIntance'

export const getJobLists = (joblists, pagination) => {
  return {
    type: jobs.GET_JOB_LISTINGS,
    payload: { jobs: joblists, pagination: pagination },
  }
}

export const isLoading = (loading) => {
  return {
    type: jobs.IS_LOADING,
    payload: loading,
  }
}

export const setModal = (modal) => {
  return {
    type: jobs.SET_MODAL,
    payload: modal,
  }
}

export const setNotification = (type, message) => (dispatch) => {
  dispatch({
    type: jobs.SET_NOTIFICATION,
    payload: { type: type, message: message },
  })
}

export const closeJob = (id) => async (dispatch) => {
  dispatch(isLoading(true))
  try {
    const result = await axiosInstance.post(`/job/close`, { job_id: id })
    const successMessage = result?.data?.message

    dispatch(setNotification('success', successMessage))
    dispatch({
      type: jobs.SELECT_JOB,
      payload: result?.data?.resp,
    })
  } catch (error) {
    const errorMessage = error?.data?.message || 'Something went wrong.'
    dispatch(setNotification('error', errorMessage))
  } finally {
    dispatch(setModal(''))
    dispatch(isLoading(false))
  }
}

export const selectJob = (job) => (dispatch) => {
  dispatch({
    type: jobs.SELECT_JOB,
    payload: job,
  })
}
