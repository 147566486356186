import React, { useEffect, useState, useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import Select from '../../../../components/dashboard/controls/Select'
import MainModal from '../../../../components/dashboard/modal/MainModal'
import ModalHead from '../../../../components/dashboard/modal/ModalHead'
import { SingleDropdownSelect } from '../../../../components/dashboard/TalentPool/DropdownSelect'
import Flex from '../../../../components/layouts/Flex'

import status from '../../../../constant/interviewStatus'

import useMutation from '../../../../hooks/useMutation'

import { setModal } from '../../../../store/actions/listsActions'
import {
  setRecruiters,
  setUpdatedStage
} from '../../../../store/actions/recruitersActions'

import { P } from '../../../../style/Typography.style'

import axiosInstance from '../../../../services/config/AxiosIntance'

const UpdateStage = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { selectedInterview, recruiters } = useSelector(
    (state) => state.requisitions
  )

  const { data, error, handleRequest, loading, success } = useMutation()

  const [allStages, setAllStages] = useState([])
  const [stage, setStage] = useState(0)
  const [stages, setStages] = useState([])
  const [applicantStatus, setApplicantStatus] = useState('')
  const [role, setRole] = useState('')
  const [recruiter, setRecruiter] = useState('')
  const [calendarUrls, setCalendarUrls] = useState([])
  const [selectedUrl, setSelectedUrl] = useState('')
  const [currStage, setCurrStage] = useState(0)

  useEffect(() => {
    axiosInstance
      .get('/stages')
      .then((res) => {
        const result = res?.data?.result
        if (result.length) {
          const filteredStages = result
            .filter((stage) => stage.id > selectedInterview?.stage_id)
            .map((stage) => ({
              value: stage.id,
              label: stage.name,
            }))
          setAllStages(result)
          setStage(filteredStages[0].value)
          setStages(filteredStages)

          const currStage = result.filter(val => val.id === selectedInterview?.stage_id)[0].code
          setCurrStage(currStage)
        }
      })
      .catch((error) => {
        if (error) {
          addToast(
            error?.response?.data?.message ||
            error?.response?.message ||
            'Something went wrong',
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        }
      })
    setRole(selectedInterview?.role)
    setApplicantStatus(status[0].value)
    setRecruiter(selectedInterview?.recruiter_id)
  }, [selectedInterview])

  function handleSelectRecruiter(e) {
    setRecruiter((prevState) => {
      return {
        ...prevState,
        recruiter_id: e.target.value,
        recruiter_name: e.target.label,
      }
    })
    setSelectedUrl('')
  }

  function handleSelectCalendarUrl(e) {
    setSelectedUrl(e.target.value)
  }

  const getStageId = useCallback(
    (name) => {
      if (allStages.length) {
        return parseInt(allStages.filter((stage) => stage.name === name)[0].id)
      }
      return 0
    },
    [allStages]
  )

  function handleUpdateInterview() {
    if (stage === getStageId('1st Interview')) {
      if (!selectedUrl) {
        return addToast('Calendly Url field is required.', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }

    if (stage !== getStageId('Closed')) {
      if (!recruiter?.recruiter_id)
        return addToast('Please select an interviewer', {
          appearance: 'error',
          autoDismiss: true,
        })

      const data = {
        recruiter_id: recruiter.recruiter_id.toString(),
        applicant_id: selectedInterview.applicant_id.toString(),
        job_id: selectedInterview.job_id.toString(),
        role: role,
        stage_id: stage,
        calendar_url: selectedUrl,
      }

      handleRequest(`/interviews/${selectedInterview.id}/update`, data, 'PUT')
    } else {
      if (applicantStatus === 'Accepted') {
        dispatch(setModal('acceptApplicant'))
      } else if (applicantStatus === 'Rejected') {
        dispatch(setModal('rejectApplicants'))
      }
    }
  }

  function handleSelectStage(e) {
    const value = parseInt(e.target.value)
    setStage(value)

    let userTypeParams = [1]

    if (value !== getStageId('1st Interview')) {
      userTypeParams = [1, 5]
    }

    axiosInstance
      .get(`/users/all?userTypeId=${JSON.stringify(userTypeParams)}`)
      .then((res) => {
        dispatch(setRecruiters(res?.data?.resp))
      })
  }

  function handleSelectApplicantStatus(e) {
    setApplicantStatus(e.target.value)
  }

  // if request is success, close the modal
  useEffect(() => {
    if (data) {
      addToast(data.message, {
        appearance: 'success',
        autoDismiss: true,
      })
      dispatch(setUpdatedStage(currStage))
      dispatch(setModal(''))
    }
  }, [success])

  useEffect(() => {
    if (error) {
      addToast(
        error?.response?.data?.message ||
        error?.response?.message ||
        'Something went wrong',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  useEffect(() => {
    if (recruiter.recruiter_id) {
      axiosInstance
        .get(`/${recruiter.recruiter_id}/calendly_url`)
        .then((res) => {
          setCalendarUrls(res.data.data.calendly_urls)
        })
        .catch((err) => console.log(err))
    }
  }, [recruiter.recruiter_id])

  useEffect(() => {
    dispatch(setUpdatedStage(''))
  }, [])

  return (
    <>
      <MainModal
        buttonText="Update"
        loading={loading}
        onClick={handleUpdateInterview}
        header={
          <ModalHead
            name={selectedInterview.applicantName}
            role={selectedInterview.role}
          />
        }
      >
        <Flex direction="column" gap="0.4rem" margin="0 0 1rem 0">
          <P size="0.85rem">Stage</P>
          <Select
            height="2.5rem"
            onchange={(e) => handleSelectStage(e)}
            defaultValue={stage}
            options={stages}
            value={stage}
          />
        </Flex>

        {stage === getStageId('Closed') && (
          <Flex direction="column" gap="0.4rem" margin="0 0 1rem 0">
            <P size="0.85rem">Status</P>

            <Select
              height="2.5rem"
              onchange={(e) => handleSelectApplicantStatus(e)}
              defaultValue={applicantStatus}
              options={status}
              value={applicantStatus}
            />
          </Flex>
        )}

        {stage !== getStageId('Closed') && (
          <Flex direction="column" gap="0.4rem" margin="1rem 0 0 0">
            <SingleDropdownSelect
              onChange={(e) => {
                handleSelectRecruiter(e)
              }}
              title="Interviewer"
            >
              <option value="">Choose an option</option>
              {recruiters?.map((recruiter) => {
                if (recruiter?.name) {
                  return (
                    <option
                      key={recruiter?.id}
                      value={recruiter?.id}
                      data-recruiterName={recruiter?.name}
                    >
                      {recruiter?.name}
                    </option>
                  )
                }
                return null
              })}
            </SingleDropdownSelect>
          </Flex>
        )}

        {stage === getStageId('1st Interview') && (
          <Flex direction="column" gap="0.4rem" margin="1rem 0 0 0">
            <SingleDropdownSelect
              onChange={(e) => {
                handleSelectCalendarUrl(e)
              }}
              title="Calendly Url"
            >
              <option value="">Choose an option</option>
              {calendarUrls?.map((url, index) => {
                return (
                  <option key={index} value={url}>
                    {url}
                  </option>
                )
              })}
            </SingleDropdownSelect>
          </Flex>
        )}
      </MainModal>
    </>
  )
}

export default UpdateStage

export const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;

  &.mt {
    margin-top: 1rem;
  }

  .datetime-icon {
    position: absolute;
    bottom: 0.75rem;
    left: 0.75rem;
    font-size: 1rem;
    color: #aeb3c9;
    z-index: 1;
  }

  .date-picker {
    width: 100%;
    height: 2.5rem;
    border: 1px solid #aeb3c9;
    padding: 0 2rem;
    padding-left: 2.5rem;
    font-size: 0.75rem;
  }
`
