import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PrivateRoute from './utils/ProtectedRoute'

import 'bootstrap/dist/css/bootstrap.css'

import Login from './pages/Login'
import UpdatePassword from './pages/UpdatePassword'
import ForgetPassword from './pages/ForgetPassword'
import ResetPassword from './pages/ResetPassword'
import VerificationEmail from './pages/VerificationEmail'

import { ToastProvider } from 'react-toast-notifications'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

import { getUser } from './store/actions/authActions'
import ResetSuccess from './pages/ResetSuccess'
import Dashboard from './pages/dashboard'

import 'react-circular-progressbar/dist/styles.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <ToastProvider autoDismiss autoDismissTimeout={6000}>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/*" element={<Dashboard />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/update-password" element={<UpdatePassword />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password/:id" element={<ResetPassword />} />
          <Route path="/verify-email" element={<VerificationEmail />} />
          <Route path="/reset-success" element={<ResetSuccess />} />
        </Routes>
      </BrowserRouter>
    </ToastProvider>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogin: state.auth.isLogin,
    user: state.auth.user,
    userId: state.auth.userId,
  }
}

export default connect(mapStateToProps, { getUser })(App)

// eslint-disable-next-line react/no-typos
App.propTypes = {
  isLogin: propTypes.bool,
  userId: propTypes.string,
  user: propTypes.object,
  getUser: propTypes.func.isRequired,
}
