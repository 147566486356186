import React, { useRef } from 'react'

import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { AiFillCloseCircle } from 'react-icons/ai'

import { P } from '../../style/Typography.style'

import OtherInfo from './OtherInfo'
import Preview from './Preview'

import { displayHTML } from '../../store/actions/talentActions'

import { SET_DOWNLOAD_MODAL } from '../../store/actions/types'

const CvWrapper = () => {
  const dispatch = useDispatch()
  const downloadRef = useRef(null)

  const handleDownloadModal = () => {
    dispatch({ type: SET_DOWNLOAD_MODAL, payload: false })
    dispatch(displayHTML(''))
  }

  const handlePrint = useReactToPrint({
    content: () => downloadRef.current,
  })

  const isValidSelector = (selector) => {
    try {
      document.createDocumentFragment().querySelector(selector)
      return true
    } catch (e) {
      return false
    }
  }

  const getSpecificStyles = (element) => {
    let styles = ''
    const styleSheets = Array.from(document.styleSheets)

    const selectorMatchesElement = (selector, element) => {
      if (!isValidSelector(selector)) return false
      try {
        return element.matches(selector)
      } catch (e) {
        return false
      }
    }

    styleSheets.forEach((sheet) => {
      try {
        const rules = sheet.cssRules || sheet.rules
        for (const rule of rules) {
          if (rule.selectorText) {
            const selectors = rule.selectorText.split(',')
            if (
              selectors.some((selector) => {
                const trimmedSelector = selector.trim()
                return (
                  selectorMatchesElement(trimmedSelector, element) ||
                  (isValidSelector(trimmedSelector) &&
                    element.querySelector(trimmedSelector))
                )
              })
            ) {
              styles += rule.cssText + '\n'
            }
          }
        }
      } catch (e) {
        return false
      }
    })

    return styles
  }

  const handleViewHTML = () => {
    if (!downloadRef.current) return

    const html = downloadRef.current.innerHTML
    const styles = getSpecificStyles(downloadRef.current)

    const fullHTML = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Preview</title>
        <style>
          ${styles}
        </style>
      </head>
      <body>
        ${html}
      </body>
      </html>
    `

    dispatch(displayHTML(fullHTML))
  }

  return (
    <StyledWrapper>
      <P size="1.5rem" color="#0E1324">
        Preview CV
      </P>

      <div className="contentWrapper">
        <Preview downloadRef={downloadRef} />
        <OtherInfo handlePrint={handlePrint} handleViewHTML={handleViewHTML} />

        <AiFillCloseCircle
          onClick={handleDownloadModal}
          className="closeIcon"
        />
      </div>
    </StyledWrapper>
  )
}

export default CvWrapper

const StyledWrapper = styled.section`
  width: 100%;

  .contentWrapper {
    display: flex;
    align-items: flex-start;
    margin-top: 1.5rem;
    gap: 2.5rem;

    .closeIcon {
      position: absolute;
      top: 2rem;
      right: 2rem;
      z-index: 12;
      width: 2rem;
      height: 2rem;
      color: red;
      cursor: pointer;
    }
  }
`
